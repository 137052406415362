// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown      as farFaAngleDown      } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faBars           as farFaBars           } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faClock          as farFaClock          } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faComment        as farFaComment        } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faFolder         as farFaFolder         } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { faQuestionCircle as farFaQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faSearch         as farFaSearch         } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faTag            as farFaTag            } from "@fortawesome/pro-regular-svg-icons/faTag";

import { faCaretLeft      as fasFaCaretLeft      } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight     as fasFaCaretRight     } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faStar           as fasFaStar           } from "@fortawesome/pro-solid-svg-icons/faStar";

/**
 * Add regular icons
 */
library.add(farFaAngleDown, farFaBars, farFaClock, farFaComment, farFaFolder, farFaQuestionCircle, farFaSearch, farFaTag);

/**
 * Add solid icons
 */
library.add(fasFaCaretLeft, fasFaCaretRight, fasFaStar);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
